.team-productivity-tabs {
    .tab-line {
        border-radius: 8px 8px 0 0;
    }

    .nav-link {
        cursor: pointer;
    }

    .team-productivity-sub-tabs {
        .tab-bg {
            .tab-list {
                margin: 0px;
                padding: 30px 20px;
                background: $white;
                border-radius: 0 0 8px 8px;
                margin-bottom: 30px;
            }
        }
    }
}

.productivity-seletcted-checkbox-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .multiselect-dropDown {
        width: 250px;

        .css-1r4vtzz {
            background: $white;
        }
    }

}

.checkbox-filter-left {
    display: flex;
    align-items: center;

    .custom-control-label {
        padding-left: 25px;
    }

    .custom-checkbox {
        margin-right: 30px;
    }
}

.productivity-report-table {
    margin-top: 30px;
    border-radius: 8px;
    background: $white;
    width: 100%;
    overflow: auto;
    max-height: 510px;

    table {
        border-radius: 8px;
        table-layout: inherit;

        tr {}

        th {
            vertical-align: top;
            padding-bottom: 0px;

            .sub-table-data {
                margin-top: 8px;
            }
        }

        th,
        td {
            &:first-child {
                padding-left: 20px;
            }

        }

        td {
            border-right: 1px solid rgba(0, 0, 0, .1) !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.multi-column-data {
                border-right: 0px !important;
            }
        }
    }

    .multi-column-data {
        width: 200px;
        padding: 0px;

        span {
            height: 30px;
            white-space: normal;
            display: inherit;
            vertical-align: top;
            padding: 15px 15px 0;
            white-space: normal;
        }

        &.multi-column-width {
            width: 300px;
        }

    }

    .sub-table-data {

        tr {
            border-bottom: 0px;
            border-right: 1px solid rgba(0, 0, 0, .1) !important;

            th {
                &:first-child {}

                vertical-align: top;
                white-space: nowrap;
                padding-left: 15px;
                border-radius: 0;
                background: rgba($white, 0.4);
                padding-bottom: 10px;
                position: inherit;

            }

            td {
                width: 100px;
                padding-left: 15px !important;
                border-right: 0px;
                border-right: 0px !important;
            }
        }
    }

    .rbh-txt {
        max-width: 171px;
    }

    .am-txt-value {
        max-width: 171px;
    }

    .bm-txt-value {
        max-width: 150px;

    }

    .aro-txt-value {
        max-width: 150px;

    }

    .bro-agent-txt-value {
        max-width: 150px;

    }

    .date-txt-value {
        width: 100px;
    }

    .total-disb-txt-value {
        white-space: normal;
        width: 70px;
    }

    .zm-txt {
        max-width: 150px;

    }

    .arco-txt {
        max-width: 150px;

    }

    tfoot {
        position: sticky;
        bottom: 0;
        background: $white;

        tr {
            td {
                padding: 13px 9px;
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.8);
                text-align: left;
            }
        }
    }

    thead {
        position: sticky;
        top: 0;
    }
}

.team-productivity-filter {
    fieldset {
        width: 13%;
    }

    .dateragefiled {
        width: 240px;
    }
}


.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;

    .modal-main {
        position: fixed;
        background: white;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close_icn {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 21px;
        cursor: pointer;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }
}

.overflow-hidden {
    overflow: hidden;
}



.model-popup-outer {
    .modal-body {
        max-height: 470px;
        overflow-y: auto;
        width: calc(100% + 80px);
        margin-left: -40px;
        padding: 0px 40px;
    }

    .modal-main {
        border-radius: 8px;
        padding: 40px 40px;

        h2 {
            padding: 0px 0 24px 0px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }


}



.bro-agent-popup {
    .modal-main {
        width: 1160px;
    }

    .bro-agent-list-outer {
        overflow-x: auto;

        .bro-agent-selected-list {
            padding-left: 20px;
            padding-right: 15px;
            padding-top: 20px;



            ul {
                li {
                    padding-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            h2 {
                padding-bottom: 20px;
                padding-right: 30px;
            }

            .selected-list-count {
                font-size: 12px;
                font-weight: normal;
                padding-left: 5px;
            }
        }

        table {
            table-layout: inherit;

            th {
                max-width: 220px;

                &:first-child {
                    padding-left: 20px;
                    border-radius: 6px 0 0 0;

                }

                &:last-child {
                    padding-right: 20px;
                    border-radius: 0 6px 0 0;
                }

                .custom-control-label {
                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba($heading-color, 0.54);
                        padding-left: 5px;
                    }
                }
            }

            td {
                max-width: 220px;
                padding: 0px;
                vertical-align: top;
                border-right: 1px solid $border-color;
                white-space: nowrap;
                padding-right: 0px;

                &:first-child {
                    padding-left: 0px;
                    border-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                    border-right: 0px;
                }
            }

            tbody {
                tr {
                    border: 1px solid $border-color;
                    border-top: 0px;
                }
            }
        }
    }

    .select-agent-list-main {
        display: flex;
        width: 100%;


        .accordion-body {
            padding-left: 20px;
            max-height: 170px;
            overflow-y: auto;
        }

        .custom-control-label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #222222;

            &::before {
                height: 14px;
                width: 14px;
            }

            &::after {
                left: -1px;
                top: 0px;
            }
        }

        .custom-control-input {
            height: 18px;
            width: 18px;
        }

        .accordion-header {
            .custom-checkbox {
                .custom-control-input:checked~.custom-control-label::after {
                    content: "\e90c";
                    font-size: 16px !important;
                }
            }

            .custom-control-input {
                width: 100%;
            }

            .custom-control {
                width: 100%;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }

    .accordian-data-funnel {
        .accordion-header {
            .accordion-button {
                &:after {
                    content: "\e917";
                    right: 0px;
                }

                line-height: 18px;
            }

            .accordion-button:not(.collapsed):after {
                content: "\e91a";
                right: 0px;
            }
        }

        .accordion-item {
            margin-bottom: 0px;
        }
    }

    .modal-footer {
        margin: 25px 0;
        float: right;
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        padding-top: 20px;

        .search-bx {
            width: 300px;
        }

        h2 {
            padding-bottom: 0px;
        }
    }

}

.productivity-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #ccc;
    border-color: #ccc;
}



.more-filter {
    &.funnel-report-more-opt {
        // display: flex;
        // align-items: center;
        // color: $heading-color;
        margin: 0px 0px 0px 10px;

        .more-filter-txt,
        .leass-filter-txt {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-left: 5px;
            white-space: nowrap;
            margin-right: 20px;
        }

        .dropdown {
            display: flex;
            position: relative;

            .dropdown-toggle {
                display: flex;
                align-items: center;
                background: transparent;
                border: 0px;

                &::before {
                    content: "\e907";
                    font-family: dc !important;
                }
            }

            .leass-filter-txt {
                display: none;
            }

            .dropdown-menu {
                box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
                border-radius: 6px;
                width: 320px;
                padding: 30px;
                background: $white;
                // margin-top: 33px;
                z-index: 99;
            }

            &.show {
                .dropdown-menu {
                    display: block;
                    position: absolute !important;
                    // right: 0px !important;
                    top: 18px !important;

                }

                .dropdown-toggle {
                    &::before {
                        content: "\e90c";
                        font-family: dc !important;
                    }
                }

                .leass-filter-txt {
                    display: block;
                }

                .more-filter-txt {
                    display: none;
                }
            }
        }

        .more-filter-option {
            h3 {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $heading-color;
                display: block;
                margin-bottom: 16px;
            }

            .filter-form {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                fieldset {
                    width: 100%;
                    margin-right: 0px;
                }
            }
        }

        .dropdown.show {
            .dropdown-menu {
                transform: translate(-33%, 45px) !important;
                inset: initial !important;
            }
        }

        fieldset {
            margin-bottom: 20px;
        }

        .btn-submit-reset {
            &.more-filter-btn {
                margin-top: 0px;
            }
        }
    }
}

.btn-submit-reset {
    .show-report-btn {
        width: 120px;
        padding: 0px 10px;
    }
}

.searching-list {
    background: $white;
    // border: 1px solid $border-color;
    padding: 20px;
    position: absolute;
    z-index: 9;
    width: 100%;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;

    ul {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 13px;

            &:last-child {
                padding-bottom: 0px;
            }
        }

        .label-txt {
            font-weight: 600;
            font-style: italic;
        }

        .custom-control {
            width: 70%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

}


/** Added for Funnel report */
.multiselect-dropDown .css-48ayfv+div {
    width: 100%;
}


.multiselect-dropDown .css-1jllj6i-control,
.multiselect-dropDown .css-1qprcsu-option,
.multiselect-dropDown .css-1vr111p-option,
.multiselect-dropDown .css-6y1x9t-control {
    min-width: auto;
}

.multiselect-dropDown .css-1jllj6i-control {
    box-shadow: none;
}



.tab-line {
    .tab-list {
        display: flex;

        .nav-item {
            .nav-link {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                border-bottom: 2px solid transparent;
                padding-bottom: 15px;
                cursor: pointer;

                &.active {
                    font-weight: 500;
                    color: $accent-color;
                    border-color: $accent-color;
                }

            }
        }
    }
}



.sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.report-heading-right-panel {
    display: flex;
    align-items: center;

    .report-heading {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        margin-right: 0px;
    }

    .report-tab {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        display: flex;
        padding: 0px 0px;
        margin-left: 10px;

        span {
            padding: 7px 6px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;

            i {
                color: $heading-color;
                font-weight: 600;
                font-size: 12px;
            }

            &.active {
                background: #0BBDDD;
                color: $white;

                i {
                    color: $white;
                }
            }

            &:first-child {
                padding-left: 10px;

                &.active {
                    border-radius: 5px 0 0 5px;
                }

            }

            &:last-child {
                padding-right: 10px;

                &.active {
                    border-radius: 0px 5px 5px 0px;
                }

            }
        }

    }

    .btn-export {
        margin-left: 10px;
    }

}

.financer-tabs {
    .tab-line {
        background: $white !important;
        border-radius: 8px !important;
        padding: 15px 20px !important;

        .nav-item {
            margin-right: 40px;
        }
    }

    .tab-bg {
        .tab-list {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;

            .nav-item {
                margin-right: 15px;

                .nav-link {
                    background: $white;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    border-radius: 100px;
                    color: $heading-color;
                    padding: 8px 20px;

                    &.active {
                        background: $accent-color;
                        color: $white;
                    }

                }
            }
        }
    }
}

.accordian-data-funnel {
    .collapse {
        display: none;

        &.show {
            display: block;
        }
    }

    .accordion-item {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .accordion-header {
        padding: 10px 20px;
        position: relative;

        .accordion-button {
            background: transparent;
            border: 0px;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $heading-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100% !important;

            &:after {
                content: "\e907";
                background-repeat: no-repeat;
                transition: transform .2s ease-in-out;
                font-family: dc;
                position: absolute;
                right: 20px;
                position: absolute;
                right: 20px;
                font-size: 25px;

            }

            .btn-line {
                min-width: auto;
                padding: 0px 15px;
                margin-right: 50px;
                line-height: 40px;

                i {
                    margin-right: 5px;
                }
            }
        }

        .accordion-button:not(.collapsed):after {
            content: "\e90c";
        }
    }
}


.sales-report-data-table {
    width: 100%;
    overflow: auto;

    table {
        thead {
            tr {
                th {
                    white-space: normal;
                    width: 110px;
                    vertical-align: top;

                    &:first-child {
                        padding-left: 15px;
                    }

                }
            }
        }

        tbody {
            tr {
                td {
                    width: 10%;

                    &:first-child {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

.chart-report-outer {
    background: $white;
    padding: 20px;
}

.funnel-report-filter-bg {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
}

.export-button {
    width: 10% !important;
}


.car-valuation-inner-tab {
    .tab-line {
        .tab-list {
            width: 100%;
            margin-bottom: 40px;

            .nav-item {
                a {
                    margin-right: 15px;
                    font-size: 13px;
                }
            }
        }
    }

    .extrior-tab-data {
        border: 1px solid $border-color;
        border-radius: 8px;
        display: flex;

        .extrior-parameter {
            width: 50%;
            border-right: 1px solid $border-color;
            padding: 15px;
            position: relative;
            
            &.extrior-impect{
                border-right: 0px;
                ul{
                    display: block;
                    padding-top: 30px;
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 48px;
                        label{
                            font-size: 12px;
                            font-weight: 400;
                            border: 1px solid rgba(151, 151, 151, 0.5);
                            border-radius: 5px;
                            color: $heading-color;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48%;
                        }
                        textarea{
                            border: 1px solid rgba(151, 151, 151, 0.5);
                            border-radius: 5px;
                            height: 36px;
                            width: 48%;
                            padding: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            resize: none;
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            .vechile-detail-bg{
                background: #FFE4D8;
                font-size: 11px;
                font-weight: 500;
                padding: 10px;
                margin-bottom: 18px;
            }
          
        }

        .heading {
            font-size: 16px;
            font-weight: 500;
            color: #000;
            margin: 0 auto;
            top: -13px;
            position: absolute;
            text-align: center;
            left: 50%;
            transform: translatex(-50%);
            background: #fff;
            padding: 0px 5px;
        }

        .subheading {
            font-size: 13px;
            font-weight: 500;
            color: $heading-color;
            display: block;
            padding-bottom: 10px;
        }
        ul{
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-bottom: 10px;

            li {
                &.chip-control {
                    position: relative;
                    cursor: pointer;
                    font-size: 13px;
                    text-align: center;
                    margin: 0 8px 8px 0;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    -ms-border-radius: 4px;
                    width: 100%;
                    &:last-child{
                        margin-right: 0px;
                    }
    
                    input {
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        right:0px;
                        cursor: pointer;
                       
                    }
                    span {
                        display: block;
                        padding: 10px 15px;
                        border-radius: 8px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        -ms-border-radius: 4px;
                        background: #fff;
                        font-size: 12px;
                        line-height: 14px;
                        color: rgba($heading-color, 0.50);
                        vertical-align: top;
                        transition: background .2s;
                        border: 1px solid #D6D6D6;
                        cursor: pointer;
                    }
    
                    input:checked~span.match-txt{
                        background: #E2F3FF;
                        color: $heading-color;
                        border: 1px solid #A3CDEB;
                        box-shadow: 0px 2px 6px 0px #0000000D;
                    }

                    input:checked~span.notmatch-txt{
                        background: #FFE8E8;
                        color: $heading-color;
                        border: 1px solid #F0BABA;

                    }
                    
                    
                }
            }
    }
    &.interior-machine-tab {
        ul {
            overflow-x: auto;
            li {
                width: auto;
            }
        }
    }
    }
}
.doc-verfiaction-number{
    display: flex;
    align-items: center;
    justify-content: space-between;     
    font-size: 13px;
    font-weight: 500;
    color: $heading-color;
    margin: 15px 0 18px;
    label{
        width: 48%;
    }     
    span{
        border-bottom: 1px solid #A3A2A2;
        width: 48%;
    }
}
.br-right-0{
    border-right: 0px !important;
}
.remarks-txtbox{
    textarea{
        border: 1px solid $border-color;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 400;
        padding: 10px;
        width: 100%;
        resize: none;
        height: 100px;
    }
}