.deviation-config-outer {
    .top-heading {
        background: $white;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .tab-line {
        background: $white;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 20px;
        width: auto;

        &.nav-pills {
            .nav-item {
                a {
                    font-size: 14px;
                    padding-bottom: 7px;
                    white-space: nowrap;
                }
            }
        }
    }

    .tab-list {
        display: flex;
        align-items: center;

        &::after {
            display: none;
        }
    }
}

.agent-fee-top-filter-row {
    display: flex;
    background: $white;
    border-radius: 8px;
    // padding: 10px;
    margin-top: 20px;
    flex: 2;
    .agent-fee-filter-top{
        padding: 0 20px;
    }
    .agent-fee-top-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-right: 10px;

        .agent-fee-view-type {
            border: 1px solid #8C8C8C;
            border-radius: 8px;
            padding: 10px 20px;
            width: 75%;
            margin: 10px 0;

            .agent-fee-label-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #444444;

                label {
                    font-weight: 500;
                    color: $heading-color;

                }
            }

            .edit-txt {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: rgba($accent-color, 0.87);
                cursor: pointer;
            }
        }
    }
}

.agent-fee-outer {
    background: $white;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.btn-wrapper {
    width: 100%;
    justify-content: space-around;
}

.agent-fee-table {
    .agenet-fee-data {
        border-bottom: 1px solid rgba($heading-color, 0.1);
    }

    .btn-update {
        display: block;
        text-align: center;
        margin-top: 40px;
        padding-bottom: 20px;

        .btn-primary {
            min-width: 320px;

        }
    }

    .btn-delete {
        display: block;
        text-align: center;
        margin-top: 40px;
        padding-bottom: 20px;

        .btn-line {
            min-width: 320px;
        }
    }
}

.agenet-fee-data {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;

    li {
        display: flex;
        align-items: center;
        margin-top: 20px;
        width: 33%;

        .form-input {
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 150px;
            margin-left: 20px;
            padding: 10px 15px;
            margin-left: 20px;
            font-weight: 500;
            font-size: 13px;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $heading-color; font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $heading-color; font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
            }
        }

        label {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: $heading-color;
            width: 100px; 
            margin: 0 10px;
            margin-left: 50px;
            word-break: break-word;
        }
        i {
            font-size: 20px;
            cursor: pointer;
            &:hover {
                color: $accent-color;
            }
        }
        .percent-parent{
            position:relative;
            top:10px;
            .percent{ 
                position:relative;
                left:75px;
                top:-31px;
            }
        }
    }
}

.addinal-agent-fee-filed {
    .form-filed {
        display: flex;
        align-items: center;
        padding-top: 5px;
    }

    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        width: 250px;
        margin-right: 15px;
        color: $heading-color;
    }
}

.additional-agent-fee-popup {
    .modal-main {
        width: 700px;

        .material {
            width: 170px;

            input.form-input {
                height: 42px;
            }

            .form-label {
                &:before {
                    top: 12px;
                }

            }

            .form-input {
                &:focus {
                    ~label {
                        &:before {
                            top: -8px;
                            font-size: 12px;
                            background: #ffffff;
                        }
                    }
                }

                &:not(:placeholder-shown) {
                    ~label {
                        &:before {
                            top: -8px;
                            font-size: 12px;
                            background: #ffffff;
                        }
                    }
                }
            }
        }

    }
}

.normal-agent-fee-popup {
    .modal-main {
        width: 860px;
    }

    .radio-type-txt {
        .custom-checkbox {
            margin-left: 0px;
            margin-right: 20px;
        }

    }

    .aplication-status-outer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        fieldset {
            margin-bottom: 0px;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
        }
    }
}

.normal-view-data-table {
    h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        padding-top: 30px;
        padding-bottom: 10px;
    }

    table {

        tr {

            th,
            td {
                &:nth-child(1) {
                    width: 32%;
                    border-radius: 6px 0 0 0px;
                }

                &:nth-child(4) {
                    width: 24%;
                }

                &:nth-child(2) {
                    width: 16%;
                    border-radius: 6px 0 0 0px;
                }

                &:last-child {
                    border-radius: 0px 6px 0 0px;
                }
            }
        }

        tbody {
            tr {
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
            }
        }

    }

    .bm-history { 
        
        tr {

            th,
            td {
                &:nth-child(1) {
                    width: 20%;
                    border-radius: 6px 0 0 0px;
                }

                &:nth-child(4) {
                    width: 13%;
                }

                &:nth-child(5) {
                    width: 24%;
                }

                &:nth-child(2) {
                    width: 20%;
                    border-radius: 6px 0 0 0px;
                }

                &:last-child {
                    border-radius: 0px 6px 0 0px;
                }
            }
        }
    }
}

.set-agent-fee-popup {
    .modal {
        .modal-body {
            max-height: 460px;
        }
    }

    .modal-main {
        width: 1200px;
    }

    h2 {
        padding-bottom: 10px;
    }
}

.normal-agent-fee-sucees-popup {
    .modal-main {
        width: 450px;
    }

    .sucees-icn {
        background: #11D1AC;
        height: 56px;
        width: 56px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;

        .ic-check {
            color: $white;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .success-msg {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        color: $heading-color;
        text-align: center;

        .success-time {
            display: block;
            line-height: 30px;
            padding: 20px 0 0;
        }
    }
}

.margin-booster {
    background: $white;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.margin-booster-view-type {
            border: 1px solid #8C8C8C;
            border-radius: 8px;
            padding: 10px 20px;
            width: 36%;
            display: flex;
            align-items: center;
            margin: auto;

        .margin-booster-label-text {
            font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #444444;
        
                        label {
                            font-weight: 500;
                            color: $heading-color;
                        }
            
            .form-input{
            border: 1px;
            border-radius: 8px;
            width: 25px;
            padding: 10px 2px;
            background-color: white;
            font-weight: 500;
            font-size: 13px;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $heading-color; font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $heading-color; font-size: 14px;
                color: rgba($heading-color, 1);
                font-weight: normal;
            }
            }
            .form-input:enabled{
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 30px;
            padding: 10px 2px;
            font-weight: 500;
            font-size: 13px;
            }
            
            .edit-txt {
                font-weight: 600;
                font-size: 14px;
                padding-left: 15px;
                line-height: 20px;
                color: rgba($accent-color, 0.87);
                cursor: pointer;
            }
        }
}




.additional-conig-outer {
    .accordion-item {
        background: $white;
        border-radius: 8px;
        margin-top: 20px;

        .accordion-header {
            border-bottom: 1px solid $border-color;
            position: relative;

            .accordion-button {
                background: transparent;
                border: 0px;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
                padding: 15px 20px;
                display: block;
                width: 100%;
                text-align: left;
                cursor: pointer;

                &:after {
                    content: "\e907";
                    transition: transform .2s ease-in-out;
                    font-family: dc;
                    font-size: 20px;
                    position: absolute;
                    right: 20px;
                }

                &:not(.collapsed):after {
                    content: "\e90c";
                }
            }
        }

        .accordion-body {
            padding: 20px;
        }
    }

    fieldset {
        margin: 0px 15px 25px;
        width: calc(33% - 30px);
        &.large-input{
            width: calc(66% - 30px);
        }

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            display: block;
            margin-bottom: 5px;
        }

        .form-input {
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 10px 15px;
            font-weight: 500;

        }
    }

    .addinal-cofig-filed {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0px -20px;
        padding: 0px 5px;
        border-bottom: 1px solid $border-color;
    }


    .show-more-filed-data {
        width: 100%;
        background: #f6f9ff;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 5px;
        border-radius: 8px;
        margin: 0px 15px;
        position: relative;
        margin-bottom: 30px;

        &::before {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #f6f9ff;
            content: "";
            position: absolute;
            top: -14px;
            margin-left: 20px;
        }
        &.selected-config-1st {
            &::before{
                left:33%
            }
            
        }
        &.selected-config-2nd {
            &::before{
                left:66%
            }
            
        }
    }

    .react-select-disabled__control {
        min-height: 42px !important;
        border-radius: 8px;
        background: #f8f8f8;
    }

    .react-select-disabled__value-container {
        background: #f8f8f8;
    }

    .react-select-disabled__indicators {
        &::after {
            color: rgba($heading-color, 0.1);
        }
    }

    .form-input:read-only {
        background: #f8f8f8;
    }

    .show-hide-txt {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-decoration: underline;
        display: block;
        margin-top: 5px;
        cursor: pointer;
    }
}

.ic-createmode_editedit{
    cursor: pointer;
}

.form-input {
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 150px;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 13px;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 14px;
        color: rgba($heading-color, 1);
        font-weight: normal;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $heading-color; font-size: 14px;
        color: rgba($heading-color, 1);
        font-weight: normal;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $heading-color; font-size: 14px;
        color: rgba($heading-color, 1);
        font-weight: normal;
    }
}

.tat-breach-modal {
    justify-content: flex-start !important;
}

.toggle-disabled {
    pointer-events: none;
    opacity: 0.5;
}
.p-t-b-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.branch-config-popup {
    .modal-body {
        max-height: calc(100vh - 300px) !important;
    }
}
.agent-fee-input-radio {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    .radio-btn {
        position: relative;
        top: 0.8rem;
    }
    .percent {
        position: absolute;
        top: 10px;
    }
}
.opsee-dealer-config {
    min-height: 400px;
}

.sub-table-dynamic-booster {
    tr {
        td {
            .sub-table-dealer-txt {
                padding-left: 30px  !important;
            }
            &:last-child{
                padding-right: 20px;
            }
            &.sub-table-dealer-txt {
                width: 9.15%;
            }
            &.sub-table-dealer-min-max-txt {
                width: 7.5%;
                padding-left: 9px !important;
            }
        }
        &:last-child {
            border-bottom: 0px;
        }
    }
}

.sub-table-main {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.financier-tenure-main {
    tbody {
        tr {
            &:hover {
                background: inherit !important;
            }
        }
    }
    .sub-table-main {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
.dynamic-boster-sub-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sub-heading-select-filed{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #EDF2FF;
    margin: 20px 0;
    border-radius: 8px;
    h3{
        font-size: 20px;
        font-weight: 500;
        color: $heading-color;
    }
    .single-select{
        width: 200px;
    }
}
.min-max-sub-heading-top{
    border-radius: 0px !important;
    th{
        padding-top: 0px !important;
        &:first-child{
            padding-left: 9px !important;
            border-radius: 0px !important
        }
        &:last-child{
            padding-right: 9px !important;
            border-radius: 0 0 6px 0 !important
        }
    }
}
th.dealer-header.t-center{
    padding-bottom: 5px !important;
    border-bottom: 1px solid $border-color;
}
.br-r-0{
    border-radius: 0 6px 0 0 !important;
}
.br-0{
    border-radius: 0px !important;
}
.dynmic-booster-table-border{
    
    border: 1px solid $border-color;
    border-top-color: #e3e7f1;
    border-radius: 6px !important;
    tr{
        
            th, td{
                border-right: 1px solid $border-color;
               
                &:last-child{
                    border-right: 0px;
                }
            }
        
    }
}
.add-financer-based-popup{
    .modal-main{
        width: 500px;
    }
    .modal-body{
        overflow-y: initial !important;
    }
}
.update-financer-popup{
    .modal-main{
        width: 830px;
        .col-md-6{
            width: 50% !important;
        }
    }
}